import { Login } from './login/Login'
import { useContext } from "react"
import { AppContext } from "./App"


export const Home = () => {
    const { isLoggedIn } = useContext(AppContext)
    return <div>
        { isLoggedIn ? <h1>Logged in</h1> : <Login /> }
        </div>
}

/*
// bootstrap:
import { Container,Row,Col } from 'react-bootstrap';

<Container>
    <Row>
        <Col>1
            <Row>
                <Col>11</Col>
                <Col>12</Col>
                <Col>13</Col>
            </Row>
        </Col>
        <Col>2</Col>
        <Col>3</Col>
    </Row>
</Container>
*/

