import { Link } from "react-router-dom";
import { useContext } from "react"
import { AppContext } from "./App"


export const Navbar = () => {
  const { isLoggedIn } = useContext(AppContext)
  return (
    <div>
      <div className="shadow m-3 p-1 rounded">
        <img className="inline" src="https://flypower.hu/flypower/api/icon/headerlogo.png" style={{ width:"50%" }} alt="Headerlogo"/>
      </div>
      { isLoggedIn && 
        <div style={{margin:"10px 0 20px"}}>
          <Link to="/JellemzoCsomagok" style={{margin:"3px"}}>
            <button className="m-2 p-2 hover:scale-[1.1] bg-sky-500 text-slate-100 rounded shadow">Jellemző csomagok</button>
          </Link>
          <Link to="/TermekKategoriak" style={{margin:"3px"}}>
            <button className="m-2 p-2 hover:scale-[1.1] bg-sky-500 text-slate-100 rounded shadow">Termék kategóriák</button>
          </Link>
          <Link to="/Bevetelezes" style={{margin:"3px"}}>
            <button className="m-2 p-2 hover:scale-[1.1] bg-sky-500 text-slate-100 rounded shadow">Áru bevételezése</button>
          </Link>
        </div> 
      }
    </div>
  );
};