import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState, createContext } from 'react'

import { Home } from "./Home"
import { JellemzoCsomagok } from "./pages/JellemzoCsomagok"
import { TermekKategoriak } from "./pages/TermekKategoriak"
import { Bevetelezes } from "./pages/Bevetelezes"
import { Navbar } from "./Navbar"

export const AppContext = createContext()

function App() {
  const API = "https://flypower.hu/flypower/api/"
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  return (
    <div className="App">
      <AppContext.Provider value={{API, isLoggedIn, setIsLoggedIn}}>
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/JellemzoCsomagok" element={<JellemzoCsomagok />} />
            <Route path="/TermekKategoriak" element={<TermekKategoriak />} />
            <Route path="/Bevetelezes" element={<Bevetelezes />} />
            <Route path="*" element={<h1> PAGE NOT FOUND</h1>} />
          </Routes>
        </Router>
      </AppContext.Provider>
    </div>
  );
}
 export default App