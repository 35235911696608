import { useContext, useState } from "react"
import { AppContext } from "../App"
import { useEffect } from 'react'
import Axios from 'axios'
// bootstrap:
import { Form,FloatingLabel } from 'react-bootstrap';

export const JellemzoCsomagok = () => {
    const { API } = useContext(AppContext)
    const [ AjellemzoCsomagok, setAjellemzoCsomagok ] = useState([])

    let api = `${API}get_jellemzo_csomagok.php`
    //console.log(api);


    useEffect(() => {
        Axios.get(api)
        .then(response => {
            //console.dir(response.data)
            if ( response.data > "0" ) {
                setAjellemzoCsomagok(response.data)
            } else {
            }
        })
        .catch(error => {
            alert(error +'\n\n'+ api)
        })
    },[])   // a [] egy függőségi tömb, amely ha üres, akkor csak 1x fut le a cucc


    const handleChange = (event, key) => {
        // A tömböt frissítjük a setUsers hookkal
        setAjellemzoCsomagok((prevUsers) => {
            // Lemásoljuk az előző tömböt
            const temp = [...prevUsers];
            // Frissítjük a kiválasztott objektum nevét
            temp[key][event.target.name] = event.target.value;
            // Visszaadjuk az új tömböt
            return temp;
        })
        let jcs = AjellemzoCsomagok[key]
        jcs[event.target.name] = event.target.value
        //console.dir(jcs)
        let saveApi = `${API}save_jellemzo_csomag.php`
        let body = JSON.stringify({
            id : jcs.id,
            megnevezes_hun : jcs.megnevezes_hun,
            megnevezes_eng : jcs.megnevezes_eng,
            megnevezes_ger : jcs.megnevezes_ger,
        })
        //console.log(saveApi,body)
        Axios.post(saveApi,body)
        .then(response => {
            //console.log(response.data)
        })
        .catch(error => {
            alert(error +'\n\n'+ saveApi)
        })
    }

    const EgyElem = (props, key) => {
        return (
            <div className="flex flex-col h-10 p-1 w-[auto] bg-slate-400 m-1 rounded text-nowrap shadow-md hover:scale-[1.05]" key={key} 
                onDoubleClickCapture={(event) => handleClick(event, key)}>
                <p className="text-xs listaelem-text" key={key}>{props.megnevezes}</p>
            </div>
        )
    }
    const handleClick = (props,key ) => {
        console.log(props.target,key)
    }


return ( 
    <div className="">
        { AjellemzoCsomagok.map((elem, key) => {
            return (
            <div className="flex flex-col m-3 p-1 rounded shadow-md bg-stone-300 cursor-pointer" key={key}>
                <div className="flex flex-row overflow-x-auto p-2">
                    <div className="m-1 flex-shrink-0 hover:scale-[1.03]">
                        <FloatingLabel label="Megnevezés HUN">
                            <Form.Control className="rounded shadow-md" type="text" value={elem.megnevezes_hun} name="megnevezes_hun" onChange={(event) => handleChange(event, key)}/>
                        </FloatingLabel>
                    </div>
                    <div className="m-1 flex-shrink-0 hover:scale-[1.03]">
                        <FloatingLabel label="Megnevezés ENG">
                            <Form.Control className="rounded shadow-md" type="text" value={elem.megnevezes_eng} name="megnevezes_eng" onChange={(event) => handleChange(event, key)}/>
                        </FloatingLabel>
                    </div>
                    <div className="m-1 flex-shrink-0 hover:scale-[1.03]">
                        <FloatingLabel label="Megnevezés GER">
                            <Form.Control className="rounded shadow-md" type="text" value={elem.megnevezes_ger} name="megnevezes_ger" onChange={(event) => handleChange(event, key)}/>
                        </FloatingLabel>
                    </div>
                </div>
                <div className="flex flex-row m-1 pb-1 rounded overflow-x-auto ">
                    { elem.listaelemek.map((listaelem, key) => {
                        return (
                            <EgyElem megnevezes={listaelem.megnevezes_hun} index={listaelem.index} key={key}/>
                        )
                    })}                        
                </div>
            </div>
            )
        })} 


    </div>
)
    
}





