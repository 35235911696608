import { useContext, useState, useEffect } from "react"
import { AppContext } from "../App"
import Axios from 'axios'
import ImageUploading from 'react-images-uploading';

// bootstrap:
import { Form, InputGroup } from 'react-bootstrap';

export const TermekKategoriak = () => {
    const { API } = useContext(AppContext)
    const [ Akategoriak, setAkategoriak ] = useState([])
    const [ Atermekek, setAtermekek ] = useState([])
    const [ selectedKategoria, setSelectedKategoria] = useState(0)
   
    //console.log(api);


    useEffect(() => {
        //setSelectedKategoria(0)
        //reload()
        let api = `${API}get_kategoriak.php`
        console.log(api)
        Axios.get(api)
        .then(response => {
            console.log("response data:")
            console.dir(response.data[0].termek_nevek)
            if ( response.data > "0" ) {
                setAkategoriak(response.data)
                setAtermekek(response.data[0].termek_nevek)
            }
        })
        .catch(error => {
            alert(error +'\n\n'+ api)
        }) 
    },[])   // a [] egy függőségi tömb, amely ha üres, akkor csak 1x fut le a cucc


    const reload = () => { 
        let api = `${API}get_kategoriak.php`
        //console.log(api) 
        console.log("reload - selectedKategoria",selectedKategoria)
        setAkategoriak([])
        Axios.get(api)
        .then(response => {
            console.dir("Reload responde data:",response.data)
            if ( response.data > "0" ) {
                setAkategoriak(response.data)
                setSelectedKategoria(selectedKategoria)
                setAtermekek(response.data[selectedKategoria].termek_nevek)
            } else {
                alert("webserver hiba!")
            }
        })
        .catch(error => {
            alert(error +'\n\n'+ api)
        })
    }

    const handleChange = (event, key) => {
        //console.log("key: ",key)
        // A tömböt frissítjük a setUsers hookkal
        setAtermekek((prevUsers) => {
            // Lemásoljuk az előző tömböt
            const temp = [...prevUsers];
            // Frissítjük a kiválasztott objektum nevét
            temp[key][event.target.name] = event.target.value;
            // Visszaadjuk az új tömböt
            return temp;
        })
        let jcs = Atermekek[key]
        jcs[event.target.name] = event.target.value
        //console.dir(jcs)
        let saveApi = `${API}save_termek_nev.php`
        let body = JSON.stringify({
            id : jcs.id,
            megnevezes : jcs.megnevezes,
            ar_huf: jcs.ar_huf,
            ar_euro: jcs.ar_euro,
            leiras_hun : jcs.leiras_hun,
            leiras_eng : jcs.leiras_eng,
            leiras_ger : jcs.leiras_ger,
        })
        //console.log(saveApi,body)
        Axios.post(saveApi,body)
        .then(response => {
            console.log(response.data)
        })
        .catch(error => {
            alert(error +'\n\n'+ saveApi)
        })
    }

    const handleSelectKategoria = (index ) => { 
        //let index = props.target.dataset.index
        console.log(index)
        setSelectedKategoria(index)
        setAtermekek(Akategoriak[index].termek_nevek)
        //reload()
    }

    const EgyKategoria = (props, key) => {
        //console.log(props.megnevezes, props.index, selectedKategoria ) 
        if ( props.index == selectedKategoria ) {
            return (
                <button className="m-2 p-2 hover:scale-[1.1] bg-slate-900 text-slate-100 rounded shadow" key={key}
                    index={props.index} onClick={(event) => handleSelectKategoria(props.index)}>{props.megnevezes}</button>
            ) 
        } else {
            return (
                <button className="m-2 p-2 hover:scale-[1.1] bg-slate-400 text-slate-100 rounded shadow" key={key}
                    index={props.index} onClick={(event) => handleSelectKategoria(props.index)}>{props.megnevezes}</button>
            )
        }
    }

    const [images, setImages] = useState([]);
    //const maxNumber = 10; // A maximális képszám

    const onChange = (imageList, addUpdateIndex) => {
        // console.log("index: ",addUpdateIndex)
        // console.log(imageList)
        // A képek frissítése
        setImages(imageList);
        imageList.map((kep) => {
            //console.log(kep)
            // console.log(Atermekek[addUpdateIndex])
            //// ide jöhet az upload API
            //var filenev = args.object.filenev


            var d = new Date()
            var filenev = d.getTime()+".jpg"
            var PARAMETERS = JSON.stringify({
                mappa       : "upload/termekgaleria/"+Atermekek[addUpdateIndex].id+"/",
                objektum_id : Atermekek[addUpdateIndex].id,
                filenev     : filenev,
                tipus       : "termekfotó",
                db_table    : "termeknev_galeria",
                kep         : kep.data_url,
            })
            console.error(PARAMETERS)
            let uploadApi = `${API}upload_objektum_kep.php`
            Axios.post(uploadApi,PARAMETERS)
            .then(response => {
                console.log(response.data)
                reload()
            })
            .catch(error => {
                alert(error +'\n\n'+ uploadApi)
            })
        })
    };

    const deleteKep = (event,kep) => {
        console.log(kep)
        let body = JSON.stringify({
            kep_id : kep.kep_id,
            filenev : kep.filenev,
            mappa: kep.mappa,
        })
        let api = `${API}delete_termeknev_kep.php`

        Axios.post(api,body)
        .then(response => {
            console.log(response.data)
            reload()
        })
        .catch(error => {
            alert(error +'\n\n'+ api)
        })

        //console.log(event)
    }

return ( 
    <div className="">
        <div className="flex flex-row m-1 pb-1 rounded overflow-x-auto ">
            { Akategoriak.map((kategoria, key) => {
                return (
                    <EgyKategoria megnevezes={kategoria.megnevezes_hun} index={kategoria.index} key={key}/>
                )
            })} 
        </div>
        <div className="flex flex-col m-1 pb-1 rounded">
            { Atermekek.map((termek, key) => {
                return (
                    <div className="flex flex-col m-1 p-0.5 rounded shadow-md bg-stone-400" key={key}>
                        <div className="flex flex-row overflow-x-auto p-2 justify-between">
                                <InputGroup className="w-200">
                                    <InputGroup.Text>Megnevezés</InputGroup.Text>
                                        <Form.Control className=" hover:scale-[1.01] text-orange-500 text-lg font-bold" type="text" value={termek.megnevezes} name="megnevezes" onChange={(event) => handleChange(event, key)}/>
                                </InputGroup>
                                <InputGroup className="w-72 ml-3">
                                    <Form.Control className="hover:scale-[1.03] " type="text" value={termek.ar_huf} name="ar_huf" onChange={(event) => handleChange(event, key)}/>
                                    <InputGroup.Text>Ft </InputGroup.Text>
                                </InputGroup>
                                <InputGroup className="w-72 ml-3">
                                    <Form.Control className="hover:scale-[1.03]" type="text" value={termek.ar_euro} name="ar_euro" onChange={(event) => handleChange(event, key)}/>
                                    <InputGroup.Text>Euro</InputGroup.Text>
                                </InputGroup>
                        </div>
                        <div className="flex flex-row overflow-x-auto p-2 h-[100px]">
                            <Form.Control as="textarea" className="rounded shadow-md hover:scale-[1.01]" type="text" value={termek.leiras_hun} name="leiras_hun" onChange={(event) => handleChange(event, key)}/>
                            <Form.Control as="textarea" className="ml-2 rounded shadow-md hover:scale-[1.01]" type="text" value={termek.leiras_eng} name="leiras_eng" onChange={(event) => handleChange(event, key)}/>
                            <Form.Control as="textarea" className="ml-2 rounded shadow-md hover:scale-[1.01]" type="text" value={termek.leiras_ger} name="leiras_ger" onChange={(event) => handleChange(event, key)}/>
                        </div>
                        <div className="flex flex-row">
                            <div className="" key="{key}">
                                <ImageUploading
                                    multiple // Több kép kiválasztásának engedélyezése
                                    value={images}
                                    onChange={(event) => onChange(event, key) }
                                    key={key}
                                    maxNumber={10}
                                    dataURLKey="data_url">
                                    {({ imageList, onImageUpload, isDragging, dragProps }) => (
                                        // A képek megjelenítése
                                        <div className="flex flex-col items-center justify-center h-[80px] w-[80px] mr-3">
                                            <button style={isDragging ? { backgroundColor: 'red', borderRadius: '100px'} : undefined} name="hanzy" onClick={onImageUpload} {...dragProps}>
                                                <img className="h-[50px] w-[50px]" src="https://flypower.hu/flypower/api/icon/plus.png" alt="upload"/>
                                            </button>
                                        </div>
                                    )}
                                </ImageUploading>                            
                            </div> 
                            <div className="flex flex-row overflow-x-auto p-0">  
                                { termek.kepek.map((kep, key) => { 
                                    return (
                                        <div className="flex flex-col items-center justify-center mr-2 p-0 w-30"> 
                                            <img draggable={true}
                                                onDragStart={e => console.log('onDragStart',key)}
                                                onDragEnd={e => console.log('onDragEnd')}
                                                onDragEnter={e => console.log('onDragEnter')}
                                                onDragLeave={e => console.log('onDragLeave')}
                                                onDragOver={e => { e.preventDefault(); console.log('onDragOver'); }} 
                                                onDrop={e => console.log('onDrop',key)}
                                                className="rounded h-16 w-16" src={kep.foto} alt={kep.index}/>
                                            <button className="mt-1 mb-1 p-1 hover:scale-[1.03] bg-orange-600 text-slate-100 rounded shadow" onClick={(event) => deleteKep(event, kep)}>
                                                Törlés
                                            </button>
                                        </div>
                                    )
                                })} 
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    </div>
)
    
}
