import { useContext } from "react"
import { AppContext } from "../App"

import Axios from 'axios'
import { useState } from 'react';
import { Form,FloatingLabel } from 'react-bootstrap';

export const Login = () => {
    const { API,setIsLoggedIn } = useContext(AppContext)
    const [userName, setUserName] = useState("")
    const [pass, setPass] = useState("")

    const handleChange = (event) => {
        console.log(event.target.value)
        switch (event.target.name) {
            case "email" : return setUserName(event.target.value)
            case "pass" : return setPass(event.target.value)
            default: return null 
        }
    }

    const loginCheck = () => {
        console.log(userName, pass, API)
        let api = `${API}login.php?mail=${userName}&pass=${pass}&fingerprint=fromWEB`
        console.log(api);
        Axios.get(api)
        .then(response => {
            console.dir(response.data)
            if ( response.data > "0" ) {
                setIsLoggedIn(true)
            } else {
                setPass("123")
                alert("HIBÁS belpési adatok!")
            }
        })
        .catch(error => {
            alert(error +'\n\n'+ api)
        });
        return
    }

    return <div className="flex flex-row w-[50%] m-auto items-center m-2 p-3 rounded shadow">
            <FloatingLabel className="m-auto" label="E-mail">
                <Form.Control className="rounded" name="email" type="text" onChange={handleChange}/>
            </FloatingLabel>
            <FloatingLabel className="m-auto" label="Jelkód (6 szám)">
                <Form.Control className="rounded" name="pass" type="text" onChange={handleChange}/>
            </FloatingLabel>
            <button className="m-auto p-3 hover:scale-[1.03] bg-sky-500 text-slate-100 rounded shadow" onClick={loginCheck}>Belépés</button>        
        </div>
}


